import React from "react";
import BottomLogo from "./../logo.png"
import { NavLink, Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <footer class="footer active bg-dark">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="footer-content">
                <div class="opening-hours">
                  <h2>Opening Hours</h2>
                  <ul>
                    <li>Monday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 8am - 4pm
                    </li>
                    <li>Tuesday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 8am - 4pm
                    </li>
                    <li>Wednesday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 8am - 4pm</li>
                    <li>Thursday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 8am - 4pm</li>
                    <li>Friday
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      8am - 4pm</li>
                    <li>Saturday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Closed</li>
                    <li>Sunday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Closed</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-sm-12 footer-content">
              <ul class="list-unstyled text-muted">
                <h5 class="text-white mb-3">Quick links</h5>
                <li>

                  <Link exact className="footerfont" to='/' >Home</Link>
                </li>
                <li>

                  <Link exact className="footerfont" to='/about' >About Us</Link>
                </li>
                <li>

                  <Link exact className="footerfont" to='/contact' >Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
