import React from "react";
import BottomLogo from "./../logo.png"
import { NavLink, Link } from "react-router-dom";
import './../StleNumericList.css';

function NumericList() {
    return (
        <div className="service">
           <h4>We are registered as private career college.</h4>
          <h3>Are you looking to get AZ license?</h3>
          <ul className="bodylist">
            <p> We are offering two tests for you with convinent price.</p>
            <p>Free Air Breake classes if you enroll for AZ MELT Program</p>
            <p>Easy payments for your convenience.</p>
          </ul>
        </div>
    );
}

export default NumericList;
