import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home'
import Contact from './pages/Contact';
import About from './pages/About';
import Header from './sharedcomponent/Header'
import Footer from './sharedcomponent/Footer'

function App() {
  return (
    <div className="App">
      <Router >
        <Header />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
