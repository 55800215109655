import React from 'react';
import SimpleSlider from '../sharedcomponent/SlickCarousel';
import ProcessStepCard from './ProcessStepCard';
import redaz from './../Asset/Img/redaz.png';
import dz from './../Asset/Img/dz.png';
import z from './../Asset/Img/z.png';
import { NavLink, Link } from "react-router-dom";
import NumericList from '../sharedcomponent/NumericList';


function Home() {
  return (
    <>
      <main>
        <div className="container-fluid bg-image main-image">
          <div className="container">
              <div className="hero-content">
                <h1 className='tagline1'>Driving Towards Your Future : </h1>
                <p className="tagline2">Unlock Your Potential with 7 Star truck driving school! </p>

                <NavLink className="btn btn-primary" activeClassName="active" to="/contact">Contact Now</NavLink> 
              </div>
          </div>
        </div>

        <div className="container cutting-edge-css">
          <h1 className='mt-4'>Our Services</h1>

          <NumericList />


          
          <br />




          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="card">
                <img src={redaz} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h1>A Course</h1>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card">
                <img src={dz} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h1>DZ Course</h1>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card">
                <img src={z} className="card-img-top" alt="..." />
                <div className="card-body">
                  <h1>BZ Course</h1>
                </div>
              </div>
            </div>

          </div>
          <br />

        </div>

        <div className="location">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2> 2722 Rena Rd, Mississauga, On L4T 3J9</h2>
                </div>
            </div>

            <div className="">
                <div className="">
                    <iframe  className="card-img-top"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2884.033934421401!2d-79.6648048231993!3d43.70984384876448!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3c1b2fa72991%3A0x93d45966b5ebec49!2s2722%20Rena%20Rd%2C%20Mississauga%2C%20ON%20L4T%203J9!5e0!3m2!1sen!2sca!4v1683583832984!5m2!1sen!2sca"
                        width="370" height="500" style={{border:0}} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <div className="card-body">
                    <div class="container">
                    <div class="row">
                        <div class="col-md-6 content-center">
                            <div class="row ">
                                <div class="call-box">
                                    <div class="call-icon">
                                    <i class="fa fa-phone"></i>
                                    </div>
                                    <div class="call-content ml-3">
                                        <h5>Call Us Today</h5>
                                        <p><a class="black-text" href="tel:905-671-4383">905-671-4383</a><br />
                                            <a class="black-text" href="tel:647-462-0514">647-462-0514</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 content-center">

                            <div class="call-box">
                                <div class="call-icon">
                                <i class="fa fa-envelope"></i>
                                </div>
                                <div class="call-content ml-3">
                                    <h5>Email Us Today</h5>
                                    <p class="email-link"><a href="mailto:hr@drivewith7star.com">hr@drivewith7star.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
      </main>
    </>
  );
}

export default Home;
