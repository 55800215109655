import React from 'react';
import SimpleSlider from '../sharedcomponent/SlickCarousel';
function About() {
  return (
    <>
      <div class="about-container content-container">
        <h1>About Us</h1>
        <p>Welcome to 7 Start Truck Driving School, the leading institution for comprehensive truck driver training. With years of experience in the industry, we are committed to providing top-quality education and preparing aspiring truck drivers for successful careers on the road.</p>

        <p>Our mission is to equip our students with the knowledge, skills, and confidence necessary to excel in the trucking industry. We understand that becoming a professional truck driver involves more than just obtaining a driver's license. It requires a deep understanding of safety regulations, advanced driving techniques, industry best practices, and the ability to navigate the challenges of the road with precision and professionalism.</p>

        <p>At 7 Start Truck Driving School, we take pride in our team of experienced instructors who are not only skilled truck drivers themselves but also dedicated educators. They bring their real-world expertise and passion for teaching to the classroom and behind-the-wheel training sessions. Our instructors are committed to providing personalized attention, ensuring that each student receives the guidance and support they need to succeed.</p>

        <p>We offer a comprehensive curriculum that covers all aspects of truck driver training, including:</p>

        <ul>
          <li>
            Classroom Instruction: Our theory-based classes cover topics such as federal regulations, safety procedures, trip planning, map reading, and logbook management. We provide a solid foundation of knowledge to help students understand the industry and its regulations.

          </li>
          <li>

            Hands-on Training: Our state-of-the-art training facilities feature modern equipment and simulation technology to provide students with practical experience. Under the guidance of our experienced instructors, students gain confidence in handling various types of trucks and learn essential skills like shifting, backing up, and maneuvering.
          </li>
          <li>

            Behind-the-Wheel Training: Students have the opportunity to apply their knowledge and skills on the road during supervised driving sessions. Our instructors accompany them to ensure they develop safe driving habits, effective communication skills, and the ability to handle different traffic situations.
          </li>
          <li>
            <strong>
              Job Placement Assistance:
            </strong>
            We understand that finding employment after completing training is crucial. Our school has established strong relationships with trucking companies, and we provide job placement assistance to our graduates. We assist with resume preparation, interview coaching, and connecting students with potential employers.
          </li>
        </ul>
        <p>

          7 Start Truck Driving School is dedicated to maintaining the highest standards of education and training. We are proud to be licensed and accredited by relevant industry bodies, ensuring that our programs meet or exceed the requirements set by regulatory authorities.
        </p>
        <p>

          Whether you're a complete novice or an experienced driver looking to enhance your skills, 7 Start Truck Driving School is here to support you on your journey toward a rewarding trucking career. Join us today and take the first step towards a successful future on the open road.
        </p>
      </div>
    </>
  );
}

export default About;
