import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mainlogo from "./../logo.png"
import React from "react";
import { NavLink, Link } from "react-router-dom";

function Home() {
    return (
        <>
            <div className="container-fluid">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 content-center desktop-button-only">
                            <div class="row ">
                                <div class="call-box">
                                    <div class="call-icon">
                                    <i class="fa fa-phone"></i>
                                    </div>
                                    <div class="call-content">
                                        <h5>Call Us Today</h5>
                                        <p><a class="black-text" href="tel:905-671-4383">905-671-4383</a><br />
                                            <a class="black-text" href="tel:647-462-0514">647-462-0514</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 content-center">
                            <div class="section">
                                <img src={mainlogo} alt="Your Logo" class="img-fluid top-image" />
                            </div>
                        </div>
                        <div class="col-md-4 content-center desktop-button-only">

                            <div class="call-box">
                                <div class="call-icon">
                                <i class="fa fa-envelope"></i>
                                </div>
                                <div class="call-content">
                                    <h5>Email Us Today</h5>
                                    <p class="email-link"><a href="mailto:hr@drivewith7star.com">hr@drivewith7star.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <nav class="navbar navbar-expand-lg bg-body-tertiary owm-nav bg-dark">
                    <div class="container-fluid ni-navdiv content-container">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul class="navbar-nav">
                                <li className="nav-item">
                                    <NavLink exact className="nav-link" activeClassName="active" to='/' >Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/about">About Us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/contact">Contact Us</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
        </>
    );
}

export default Home;
